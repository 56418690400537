<template>
  <div>
    
   <h1>マイページトップメニュー</h1>
 
    <p v-if="logined&&loaded" >こんにちは、  {{common.mydata.sei.value}}   {{common.mydata.mei.value}}さん</p>

<partmenu v-if="logined" />

<div class="totopbn">[<router-link to="/">トップページへ戻る</router-link>]</div>

    <login v-if="!logined" v-model="logined" />

  </div>
</template>

<script>
import partmenu from '@/components/part_menu.vue'; // @ is an alias to /src

export default  {
  components: {
    partmenu,
  },created:function(){
    this.$parent.middle = 2; 
    
    this.loaded_countmax=1;
    if(!this.common.mydata){
      this.login("mypage").then(()=>{
          this.loaded_countup();
      }).catch((data) => {
          this.loaded_countup();
      });
    }else{
          this.loaded_countup();
    }
  }
}
</script>

<style scoped lang="scss">
h1{
  margin: 1em 0;
}
</style>